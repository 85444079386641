<template>
  <div class="page-container page-hero">
    <div class="page-hero__bg"></div>
    <h1 class="page-hero__title text-largetitle">Поле</h1>
    <p class="page-content__description">
    Поле — дизайн-система цифровых продуктов Магнита, которая помогает быстрее собирать продукты без ущерба качеству и клиентскому опыту.
    </p>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style lang="scss">
.page-hero {
  &__bg {
    background: url(../assets/dipole.svg) no-repeat left;
    height: 660px;
    width: 100%;
  }
  &__title {
    padding: 72px 0 24px 0;
    font-size: 36px;
  }
}
</style>
