import { render, staticRenderFns } from "./TheLogo.vue?vue&type=template&id=1aa79b10&scoped=true&"
import script from "./TheLogo.vue?vue&type=script&lang=js&"
export * from "./TheLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aa79b10",
  null
  
)

export default component.exports